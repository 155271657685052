const setOrgAnalytics = (state, payload) => {
  state.orgAnalytics = payload;
};

const setLandingPageResults = (state, payload) => {
  state.landingPageViewSubmits = payload.landingPageViewSubmits;
};

export default {
  setOrgAnalytics,
  setLandingPageResults,
};
