const defaultPaginationDetails = {
  currentPage: 0,
  totalPages: 0,
  pageSize: 10,
  totalData: 0,
};

export default {
  locationList: {
    data: [],
    paginationDetails: defaultPaginationDetails,
  },
};
