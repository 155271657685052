import { get } from "lodash";
import axios from "@/lib/axios";

export default {
  state: {
    detail: {},
    labs: [],
    appointments: [],
    accountPatient: {},
  },

  getters: {
    getAccountPatientDetail: (state) => {
      return state.accountPatient;
    },
    getCurrentPatientDetail: (state) => {
      return state.detail;
    },
    getCurrentPatientLabs: (state) => {
      return state.labs;
    },
    getCurrentPatientAppointments: (state) => {
      return state.appointments;
    },
    getPatientLabs: (state) => {
      return state.labs;
    },
    getPatientAppointments: (state) => {
      return state.appointments;
    },
    getInsertedPatientAppointments: (state) => {
      return state.appointments.filter((appointment) => {
        return appointment.status === "inserted";
      });
    },
  },

  mutations: {
    setCurrentPatientDetail(state, payload) {
      state.detail = payload;
    },
    setCurrentPatientAppointments(state, payload) {
      state.appointments = payload;
    },
    setPatientLabs(state, payload) {
      state.labs = payload;
    },
    // TODO: duplicate
    setCurrentPatientLabs(state, payload) {
      state.labs = payload;
    },
    setPatientAppointments(state, payload) {
      state.appointments = payload;
    },
    setAccountPatientDetail(state, payload) {
      state.accountPatient = payload;
    },
  },

  actions: {
    // Fetch
    fetchAccountPatient: async ({ commit }, payload) => {
      const { patientId } = payload;
      const response = await axios.get(`/accounts/v1/patient/${patientId}`);
      commit("setAccountPatientDetail", response.data);
    },
    fetchPatient: async ({ commit }, payload) => {
      const { locationId, patientId } = payload;
      const response = await axios.get(`/patient/v1/location/${locationId}/patient/${patientId}`);
      commit("setCurrentPatientDetail", response.data);
    },
    fetchPatientLabs: async ({ commit }, payload) => {
      const { locationId, patientId } = payload;
      const response = await axios.get(`/patient/v1/location/${locationId}/patient/${patientId}/lab`);
      commit("setCurrentPatientLabs", response.data);
      commit("setCurrentAppointmentLabs", response.data);
    },
    // TODO: duplicate?
    fetchConsultPatientLabs: async ({ commit }, payload) => {
      const { locationId, patientId } = payload;
      const response = await axios.get(`/patient/v1/location/${locationId}/patient/${patientId}/lab`);
      commit("setPatientLabs", response.data);
    },
    fetchConsultPatientAppointments: async ({ commit }, payload) => {
      const { locationId, patientId } = payload;
      const response = await axios.get(`/patient/v1/location/${locationId}/patient/${patientId}/appointment`);
      commit("setPatientAppointments", response.data);
    },
    // TODO: duplicate?
    fetchPatientAppointments: async ({ commit }, payload) => {
      const { locationId, patientId } = payload;
      const response = await axios.get(`/patient/v1/location/${locationId}/patient/${patientId}/appointment`);
      commit("setCurrentPatientAppointments", response.data);
    },

    // Create
    addNewPatient: async ({ dispatch }, payload) => {
      const { locationId } = payload;
      const body = {
        firstName: payload.firstName,
        lastName: payload.lastName,
        sex: payload.sex,
        dob: payload.dob,
        socialSecurity: payload.socialSecurity,
        heightInches: payload.heightInches,
        weightPounds: payload.weightPounds,
        ethnicity: payload.ethnicity,
      };
      const response = await axios.post(`/patient/v1/location/${locationId}/patient`, body);
      if (response.status === 200) {
        dispatch("fetchLocationPatients", locationId);
      }
    },
    addNewLabToPatient: async ({ dispatch }, payload) => {
      const { locationId, patientId } = payload;
      const body = {
        date: payload.date,
        values: payload.values, // [...{ type: 'estradiol', value: 25.9999 }]
      };
      const response = await axios.post(`/patient/v1/location/${locationId}/patient/${patientId}/lab`, body);
      payload = { locationId, patientId };
      dispatch("fetchPatientLabs", payload);
    },

    updateMedcenterPatient: async ({ dispatch }, payload) => {
      const { locationId, patientId } = payload;
      const body = {
        firstName: payload.firstName,
        lastName: payload.lastName,
        sex: payload.sex,
        dob: payload.dob,
        email: payload.email,
      };
      const response = await axios.put(`/patient/v1/location/${locationId}/patient/${patientId}/medcenter`, body);
      if (response.status === 200) {
        dispatch("fetchPatient", { locationId, patientId });
      }
    },

    // Update
    updatePatient: async ({ dispatch }, payload) => {
      const { locationId, patientId } = payload;
      const socialSecurity = get(payload, "socialSecurity", "");
      const body = {
        firstName: payload.firstName,
        lastName: payload.lastName,
        sex: payload.sex,
        dob: payload.dob,
        heightInches: payload.heightInches,
        weightPounds: payload.weightPounds,
        ethnicity: payload.ethnicity,
        email: payload.email,
        socialSecurity: socialSecurity,
      };
      const response = await axios.put(`/patient/v1/location/${locationId}/patient/${patientId}`, body);
      if (response.status === 200) {
        dispatch("fetchPatient", { locationId, patientId });
      }
    },
    updatePatientLab: async ({ dispatch }, payload) => {
      const { locationId, patientId, labId } = payload;
      const body = {
        date: payload.date,
        values: payload.values, // [...{ type: 'estradiol', value: 25.9999 }]
      };
      const response = await axios.put(`/patient/v1/location/${locationId}/patient/${patientId}/lab/${labId}`, body);
      payload = { locationId, patientId };
      dispatch("fetchPatientLabs", payload);
      return response;
    },
    // TODO: Remove duplicate?
    updateLab: async (payload) => {
      const { locationId, patientId, labId } = payload;
      const body = {
        date: payload.date,
        values: payload.values, // [...{ type: 'estradiol', value: 25.9999 }]
      };
      const response = await axios.put(`/patient/v1/location/${locationId}/patient/${patientId}/lab/${labId}`, body);
    },

    // Misc
    clearCurrentPatient: async ({ commit }) => {
      commit("setCurrentPatientDetail", {});
      commit("setCurrentPatientLabs", []);
      commit("setCurrentPatientAppointments", []);
    },
    commitCurrentPatientDetail: ({ commit }, payload) => {
      commit("setCurrentPatientDetail", payload);
    },
    commitCurrentPatientAppointments: ({ commit }, payload) => {
      commit("setCurrentPatientAppointments", payload);
    },
  },
};
