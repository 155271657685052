const setPatientList = (state, payload) => {
  if (typeof payload === "object" && payload.type === "add") {
    state.patientList.unshift(payload.data);
  } else {
    state.patientList = payload;
  }
};
const setTotalPatient = (state, payload) => {
  if (payload.type === "init") {
    state.totalPatient = payload.value;
  } else if (payload.type === "add" && state.totalPatient) {
    state.totalPatient = {
      count: state.totalPatient.count + 1,
      recentlyAddedCount: state.totalPatient.recentlyAddedCount + 1,
    };
  }
};

export default {
  setPatientList,
  setTotalPatient,
};
