import GetSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/get";
import PutSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/put";
import PostSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/post";

const fetchPatientDosingAppointmentDetails = async (_, { locationId, patientId, appointmentId }) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = `dosing/v1/location/${locationId}/patient/${patientId}/appointment/${appointmentId}`;
  const response = await proxy.execute(query);
  if (response) {
    const { data } = response;
    return data;
  }
};

const calculateDosingAppointment = async (_, payload) => {
  const proxy = new PostSimpatraServiceProxy();
  const { locationId, patientId, appointmentId, patientSex } = payload;
  const isMale = patientSex === "male";
  const queryParams = isMale ? "m" : "f";
  const query = `dosing/v1/location/${locationId}/patient/${patientId}/appointment/${appointmentId}/calculate/${queryParams}`;
  const response = await proxy.execute(query, payload.body, {
    successNotificationMsg: "Successfully calculated dosing",
    displaySuccessMessage: true,
  });

  if (response.data) {
    const { data } = response;
    return data;
  } else return null;
};

const saveAppointmentDosing = async ({ dispatch, getters }, payload) => {
  const updateProxy = new PutSimpatraServiceProxy();
  const postProxy = new PostSimpatraServiceProxy();
  const { locationId, calculationId } = payload;
  const body = {
    side: payload.side,
    location: payload.location,
    notes: payload.notes,
    procedureNotes: payload.procedureNotes
  };
  const query = `dosing/v1/location/${locationId}/calculation/${calculationId}/insert`;
  let response = null;
  if (payload.isUpdate) {
    response = await updateProxy.execute(query, body, "Successfully updated appointment");
  } else {
    body.pellets = payload.pellets;
    response = await postProxy.execute(query, body, {
      successNotificationMsg: "Successfully added insertion",
      displaySuccessMessage: true,
    });
  }

  if (response.data) {
    const { data } = response;
    return data;
  } else return null;
};

export default {
  fetchPatientDosingAppointmentDetails,
  calculateDosingAppointment,
  saveAppointmentDosing,
};
