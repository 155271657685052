import GetSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/get";
import DeleteSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/delete";
import PostSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/post";
import PutSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/put";

const fetchVendorUsers = async ({ commit }, { vendorId }) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = `/accounts/v1/vendor/${vendorId}/user`;
  const response = await proxy.execute(query);
  if (response) commit("setVendorUsers", response.data);
};

const fetchVendorById = async (_, { vendorId }) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = `accounts/v1/market/vendor/${vendorId}`;
  const response = await proxy.execute(query);
  if (response) {
    const { data } = response;
    return data;
  }
};

const deleteVendorUser = async ({ commit }, { vendorId, userId }) => {
  const proxy = new DeleteSimpatraServiceProxy();
  const query = `accounts/v1/vendor/${vendorId}/user/${userId}`;
  const response = await proxy.execute(query, {
    notificationParams: {
      displaySuccessMessage: true,
      successNotificationMsg: "Successfully deleted user!",
    },
  });
  if (response) {
    commit("updateVendorUsersList", {
      type: "DELETE",
      data: response.data,
    });
  }
};

const addVendorUser = async ({ commit }, payload) => {
  const { vendorId } = payload;
  const body = {
    firstName: payload.firstName,
    lastName: payload.lastName,
    email: payload.email,
    shippingTypeId: payload.shippingTypeId,
  };

  const proxy = new PostSimpatraServiceProxy();
  const query = `accounts/v1/vendor/${vendorId}/user`;
  const response = await proxy.execute(query, body, {
    displaySuccessMessage: true,
    successNotificationMsg: "Successfully added user",
  });

  if (response) {
    commit("updateVendorUsersList", {
      type: "ADD",
      data: response.data,
    });
  }
};

const updateVendorUser = async ({ commit }, payload) => {
  const { vendorId, userId } = payload;
  const body = {
    firstName: payload.firstName,
    lastName: payload.lastName,
    email: payload.email,
    phone: payload.phone,
    shippingTypeId: payload.shippingTypeId,
  };

  const proxy = new PutSimpatraServiceProxy();
  const query = `accounts/v1/vendor/${vendorId}/user/${userId}`;
  const response = await proxy.execute(query, body, "Successfully updated user.");

  if (response) {
    commit("updateVendorUsersList", {
      type: "UPDATE",
      data: response.data,
    });
  }
};

const fetchLaboratories = async ({ commit }) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = `accounts/v1/vendor/getlabs`;
  const response = await proxy.execute(query);
  if (response) {
    const { data } = response;
    commit("setLaboratories", data);
    return data;
  }
};

const addVendorLogo = async (_, { vendorId, payload }) => {
  const proxy = new PostSimpatraServiceProxy();
  const query = `accounts/v1/vendor/${vendorId}/logo`;
  const response = await proxy.execute(query, payload, {
    displaySuccessMessage: true,
    successNotificationMsg: "Successfully added logo.",
  });
  if (response) {
    const { data } = response;
    return data;
  }
};

const fetchVendorList = async (_, { params }) => {
  const proxy = new GetSimpatraServiceProxy();
  let query = `accounts/v1/vendors`;
  let queryParams = {};
  if (params) {
    queryParams.is503aVendor = params.is503A;
  }
  const response = await proxy.execute(query, queryParams);
  if (response) {
    const { data } = response;
    return data;
  }
};

const fetchVendorShipping = async ({ commit }, { params }) => {
  const proxy = new GetSimpatraServiceProxy();
  let query = `accounts/v1/vendor/${params.vendorId}/flat-rate-shipping`;
  let queryParams = {};
  if (params) {
    queryParams.status = params.status;
  }
  const response = await proxy.execute(query, queryParams);
  if (response) {
    const { data } = response;
    commit("setVendorShipping", data);
    return data;
  }
}

const updateVendorShipping = async ({ commit }, { vendorId, vendorFlatRateShippingId, payload }) => {
  const proxy = new PutSimpatraServiceProxy();
  const query = `accounts/v1/vendor/${vendorId}/flat-rate-shipping/${vendorFlatRateShippingId}`;
  const response = await proxy.execute(query, payload, "Successfully updated shipping.");
  if (response) {
    commit("updateVendorShippingList", {
      type: "UPDATE",
      data: response.data,
    });
    return response.data;
  }
}

export default {
  fetchVendorUsers,
  fetchVendorById,
  deleteVendorUser,
  addVendorUser,
  updateVendorUser,
  fetchLaboratories,
  addVendorLogo,
  fetchVendorList,
  fetchVendorShipping,
  updateVendorShipping
};
