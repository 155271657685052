import orgModuleMutations from "./orgModule/mutations";
import adminModuleMutations from "./adminModule/mutations";
import vendorModuleMutations from "./vendorModule/mutations";
import marketModuleMutations from "./marketModule/mutations";

export default {
  ...orgModuleMutations,
  ...adminModuleMutations,
  ...vendorModuleMutations,
  ...marketModuleMutations,
};
