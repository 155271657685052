export default {
  orgAnalytics: [
    {
      searchResults: [],
      profileViews: [],
      contactSubmits: [],
    },
  ],
  landingPageViewSubmits: [],
};
