import { get } from "lodash";

const setOrgLocationsListLookup = (state, payload) => {
  const data = get(payload, "content", []);
  state.locationList.paginationDetails.currentPage = get(payload, "number", 0);
  state.locationList.paginationDetails.totalPages = get(payload, "totalPages", 0);
  state.locationList.paginationDetails.pageSize = get(payload, "size", 0);
  state.locationList.paginationDetails.totalData = get(payload, "totalElements", 0);
  state.locationList.data = data;
};

export default {
  setOrgLocationsListLookup,
};
