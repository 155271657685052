import { isEmpty } from "lodash";
import userMe from "@/services/user-me";

const getOrgDetails = (state) => {
  return state.orgDetails;
};

const getOrgLocations = (state) => {
  return state.orgLocations;
};

const getOrgActiveLocations = (state) => {
  if (isEmpty(state.orgLocations)) return [];
  return state.orgLocations.filter((location) => {
    return location.isActive === true;
  });
};

const getOrgUsers = (state) => {
  return state.orgUsers;
};

const getOrgDosingDetails = (state) => {
  return state.orgDosingDetails;
};

const getOrgDosingUsers = (state) => {
  return state.orgDosingUsers;
};

const getOrgContactInfo = (state) => {
  return {
    name: state.orgDetails.name,
    address1: state.orgDetails.address1,
    address2: state.orgDetails.address2,
    city: state.orgDetails.city,
    region: state.orgDetails.region,
    postalCode: state.orgDetails.postalCode,
    email: state.orgDetails.email,
    phone: state.orgDetails.phone,
  };
};

const getUserHasDosingAccess = (state, getters) => {
  const isSimpatraAdmin = userMe.getMyRoles().isSimpatraAdmin;
  const hasDosingAccess = userMe.getMyRoles().isSchedulingAdmin > 0 && state.orgDosingDetails.numDosingUsers > 0;
  return isSimpatraAdmin || hasDosingAccess;
};

const getOrgList = (state) => {
  return state.orgList;
};

const getOrgContacts = (state) => {
  return state.orgContacts;
};

const getOrgAssessments = (state) => {
  return state.orgAssessments;
};

const getDeaLicenses = (state) => {
  return state.deaLicenses;
};

const getMedicalLicenses = (state) => {
  return state.medicalLicenses;
};

const getNPI = (state) => {
  return state.npi;
};

const getOrgUserLookup = (state) => {
  return state.orgUserLookup;
};

export default {
  getOrgDetails,
  getOrgContactInfo,
  getOrgLocations,
  getOrgUsers,
  getOrgDosingUsers,
  getOrgDosingDetails,
  getUserHasDosingAccess,
  getOrgActiveLocations,
  getOrgList,
  getOrgContacts,
  getOrgAssessments,
  getDeaLicenses,
  getMedicalLicenses,
  getNPI,
  getOrgUserLookup,
};
