import { isEmpty, get } from "lodash";
import USER_TYPE_CONSTANTS from "@/constants/user-type-constants";
import store from "../store";

const initLocalStorageDetails = async ({ isVendorView, userType, decodedToken }) => {
  if (userType === USER_TYPE_CONSTANTS.organizationUser && !isVendorView) {
    // This is the org id of the current login, this is for security purpose
    // We always used decoded token to avoid using localStorage when they tamper the orgId
    const currentLogginOrgId = get(decodedToken.sim.details, "id", null);
    if (isEmpty(localStorage.orgId)) {
      localStorage.setItem("orgId", currentLogginOrgId);
    }
  } else if (userType === USER_TYPE_CONSTANTS.vendorUser) {
    const vendorId = get(decodedToken.sim.details, "id", "");
    if (isEmpty(localStorage.selected_vendor)) {
      localStorage.setItem("selected_vendor", vendorId);
    }
  }
};

const fetchOrgInitialDetails = async ({ userType, isSimpatraSuperAdmin }) => {
  const orgDetails = store.getters["accountsModule/getOrgDetails"];
  const isEmptyOrgDetails = isEmpty(orgDetails) || isEmpty(orgDetails.id);

  if (isEmptyOrgDetails && userType === USER_TYPE_CONSTANTS.organizationUser) {
    const orgId = localStorage.getItem("orgId");
    let selectedLocation = localStorage.getItem("currentLocation");
    const locationIsEmpty = ["", null, undefined].includes(selectedLocation);

    const fetchAccessibleLocationQueryParams = isSimpatraSuperAdmin ? { orgId } : {};
    const userAccessibleLocations = await store.dispatch("usersModule/fetchLoggedUserAccessibleLocations", fetchAccessibleLocationQueryParams);
    const currentUserAvailableLocations =  userAccessibleLocations && userAccessibleLocations.locations ? userAccessibleLocations.locations : [];
    const userActiveLocations = currentUserAvailableLocations.length > 0 ? currentUserAvailableLocations.filter(item => item.isActive === true) : null;

    selectedLocation = locationIsEmpty ? userActiveLocations[0].id : selectedLocation;
    localStorage.setItem("currentLocation", selectedLocation);

    // FETCHING DETAILS OF SPECIFIC LOCATION OF AN ORGANIZATION
    const currentLocationDetails = await store.dispatch("locationsModule/fetchLocationDetails", {
      locationId: selectedLocation
    });
    store.commit("locationsModule/setCurrentLocationDetails", currentLocationDetails); // SETTING CURRENT PAGE
    store.commit("locationsModule/setCurrentUserAvailableLocation", currentUserAvailableLocations);

    await store.dispatch("accountsModule/fetchOrgDetails", { orgId });
    store.commit("locationsModule/setSelectedCurrentLocation", selectedLocation, { root: true });
  }
};

export default {
  initLocalStorageDetails,
  fetchOrgInitialDetails
};

