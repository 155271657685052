const getVendorUsers = (state) => {
  return state.vendorUsers;
};

const getLaboratories = (state) => {
  return state.laboratories;
};
const getVendorShippings = state => {
  return state.vendorShipping;
};

export default {
  getVendorUsers,
  getLaboratories,
  getVendorShippings
};
