import GetSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/get";
import PutSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/put";
import PostSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/post";
import PatchSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/patch";

const fetchPatientDetails = async (_, patientId) => {
  const proxy = new GetSimpatraServiceProxy();
  // jpatient/v1/location/{location_id}/patient/{patient_id}
  const query = `jpatient/v1/patient/${patientId}`;
  const response = await proxy.execute(query);
  if (response) {
    const { data } = response;
    return data;
  }
};
const fetchPatientInfo = async (_, { locationId, patientId }) => {
  const proxy = new GetSimpatraServiceProxy();
  // jpatient/v1/location/{location_id}/patient/{patient_id}
  const query = `jpatient/v1/location/${locationId}/patient/${patientId}`;
  const response = await proxy.execute(query);
  if (response) {
    const { data } = response;
    return data;
  }
};

const createUpdatePatient = async (_, { type = "add", payload, locationId, patientId }) => {
  const post_proxy = new PostSimpatraServiceProxy();
  const put_proxy = new PutSimpatraServiceProxy();
  const isUpdate = type === "update";
  let query = `jpatient/v1/location/${locationId}/patient`;
  if (isUpdate) {
    query += `/${patientId}`;
  }
  let response;

  if (isUpdate) {
    const notifMsg = `Successfully updated patient!`;
    response = await put_proxy.execute(query, payload, notifMsg);
  } else {
    response = await post_proxy.execute(query, payload, {
      displaySuccessMessage: true,
      successNotificationMsg: "Successfully created patient."
    });
  }

  if (response) {
    const { data } = response;
    return data;
  }
};

const createUpdatePatientUserInfo = async (_, { type = "add", payload, patientId }) => {
  const post_proxy = new PostSimpatraServiceProxy();
  const put_proxy = new PutSimpatraServiceProxy();
  const isUpdate = type === "update";
  let query = `j-idp/v1/patient/${patientId}`;
  let response;

  if (isUpdate) {
    const notifMsg = `Successfully updated patient!`;
    response = await put_proxy.execute(query, payload, notifMsg);
  } else {
    query += "/activate";
    response = await post_proxy.execute(query, payload, {
      displaySuccessMessage: true,
      successNotificationMsg: `Successfully activated Patient.`
    });
  }

  if (response) {
    const { data } = response;
    return data;
  }
};

const updatePatientSendInvoiceStatus = async (_, { locationId, payload, patientId }) => {
  const proxy = new PatchSimpatraServiceProxy();
  let query = `jpatient/v1/location/${locationId}/patient/${patientId}`;
  let response = await proxy.execute(query, payload, {
    displaySuccessMessage: true,
    successNotificationMsg: `Successfully Update Patient Send Invoice Status.`
  });

  if (response) {
    const { data } = response;
    return data;
  }
};

const fetchPatientList = async ({ commit }, { type, locationId, searchQuery = "", isLookups = false }) => {
  const proxy = new GetSimpatraServiceProxy();
  const queryParams = {
    q: searchQuery
  };
  if (type) {
    queryParams["type"] = type;
  }
  const query = `jpatient/v1/location/${locationId}/patient/search`;
  const response = await proxy.execute(query, queryParams);
  if (response) {
    const { data } = response;
    if (isLookups) {
      return data;
    } else {
      commit("setPatientList", data);
    }
  }
};

const fetchPatientListCount = async ({ commit }, locationId) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = `jpatient/v1/location/${locationId}/patient/count`;
  const response = await proxy.execute(query);
  if (response) {
    const { data } = response;
    commit("setTotalPatient", {
      type: "init",
      value: data
    });
    return data;
  }
};

const fetchPatientAllergyHistory = async (_, { patientId, pageNumber, pageSize }) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = `jpatient/v1/patient/${patientId}/allergy-history?pageNumber=${pageNumber}&pageSize=${pageSize}`;
  const response = await proxy.execute(query);
  if (response) {
    const { data } = response;
    return data;
  }
};

const addPatientAllergyHistory = async (_, { payload, patientId }) => {
  const post_proxy = new PostSimpatraServiceProxy();
  let query = `jpatient/v1/patient/${patientId}/allergy-history`;

  const response = await post_proxy.execute(query, payload, {
    displaySuccessMessage: true,
    successNotificationMsg: `Successfully added allergy history.`
  });

  if (response) {
    const { data } = response;
    return data;
  }
};

const updatePatientAllergyHistory = async (_, { payload, patientId, allergyHistoryId }) => {
  const put_proxy = new PutSimpatraServiceProxy();
  let query = `jpatient/v1/patient/${patientId}/allergy-history/${allergyHistoryId}/status`;

  let notifMsg = "";
  if (payload?.showNotif) {
    notifMsg = `Successfully updated patient allergy history status!`;
  }
  const response = await put_proxy.execute(query, payload, notifMsg);

  if (response) {
    const { data } = response;
    return data;
  }
};

const deactivateAllPatientAllergyHistory = async (_, { payload, patientId }) => {
  const put_proxy = new PutSimpatraServiceProxy();
  let query = `jpatient/v1/patient/${patientId}/nka`;

  const notifMsg = `Successfully updated patient allergy history statuses!`;
  const response = await put_proxy.execute(query, payload, notifMsg);

  if (response) {
    const { data } = response;
    return data;
  }
};

const fetchAllPatientAllergyHistory = async (_, { patientId }) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = `jpatient/v1/patient/${patientId}/allergy-history`;
  const response = await proxy.execute(query);
  if (response) {
    const { data } = response;
    return data;
  }
};

export default {
  fetchPatientDetails,
  fetchPatientInfo,
  createUpdatePatient,
  createUpdatePatientUserInfo,
  fetchPatientList,
  updatePatientSendInvoiceStatus,
  fetchPatientListCount,
  fetchPatientAllergyHistory,
  addPatientAllergyHistory,
  updatePatientAllergyHistory,
  deactivateAllPatientAllergyHistory,
  fetchAllPatientAllergyHistory
};

