import moment from "moment";
import { get } from "lodash";
import axios from "@/lib/axios";
import { thisDay, nextDay } from "@/plugins/moment";
import notification from "@/utils/notifications";

export default {
  state: {
    locationStatus: localStorage.locationStatus,
    id: "",
    name: "",
    phone: "",
    email: "",
    website: "",
    address1: "",
    address2: "",
    city: "",
    region: "",
    postalCode: "",
    country: "",
    addressId: "",
    paymentsAch: [],
    paymentsCard: [],
    availableVendors: [],
    registrations: [],
    receipts: [],
    patients: [],
    orders: [],
    currentOrder: {},
    appointments: [],
    patientSearchResults: [],
    currentAppointment: {
      summary: {},
      labs: {},
      dose: {
        info: {},
        calculated: false,
        sex: "",
        recommendedTest: "",
        recommendedEst: "",
      },
    },
    results: [],
    page: 1,
    pageLength: 25,
    products: [],
    patientLocationId: "",
    inventoryStock: {},
    isActive: false,
    selectedCurrentLocation: "",
  },

  getters: {
    getInventoryStock: (state) => {
      return state.inventoryStock;
    },
    getPatientLocationId: (state) => {
      return state.patientLocationId;
    },
    getLocationProductsList: (state) => {
      return state.products;
    },
    getLocationReceipts: (state) => {
      return state.receipts;
    },
    getPurchaseReceipts: (state) => {
      return state.receipts;
    },

    getLocationCardAccounts: (state) => {
      return state.paymentsCard.sort((a, b) => {
        if (a.shareable > b.shareable) {
          return -1;
        }
        if (a.shareable < b.shareable) {
          return 1;
        }
        return 0;
      });
    },
    getLocationAchAccounts: (state) => {
      return state.paymentsAch;
    },
    getCurrentLocationName: (state) => {
      return localStorage.getItem("locationName");
    },
    getCurrentLocationAddress1: (state) => {
      return state.address1;
    },
    getCurrentLocationAddress2: (state) => {
      return state.address2;
    },
    getCurrentLocationCity: (state) => {
      return state.city;
    },
    getCurrentLocationRegion: (state) => {
      return state.region;
    },
    getCurrentLocationPostalCode: (state) => {
      return state.postalCode;
    },
    getCurrentLocationRegistrations: (state) => {
      return state.registrations;
    },
    getCurrentLocationRegisteredVendors: (state) => {
      const vendors = state.registrations.map((registration) => {
        return registration.vendor.id;
      });
      return vendors;
    },
    getCurrentLocationPatients: (state) => {
      return state.patients;
    },
    getLocationOrders: (state) => {
      return state.orders.sort((a, b) => {
        if (a.paidAt > b.paidAt) {
          return -1;
        }
        if (a.paidAt < b.paidAt) {
          return 1;
        }
        return 0;
      });
    },
    getPatientSearchResults: (state) => {
      return state.patientSearchResults;
    },
    getLocationAppointments: (state) => {
      return state.appointments;
    },
    getCurrentLocationAddressId: (state) => {
      return state.addressId;
    },
    getTodaysAppointments: (state) => {
      const today = new Date();
      return state.appointments
        .filter((appointment) => {
          const aptDate = new Date(appointment.date);
          return (
            aptDate.getDate() === today.getDate() &&
            aptDate.getMonth() === today.getMonth() &&
            aptDate.getFullYear() === today.getFullYear()
          );
        })
        .sort((a, b) => {
          const dateOne = new Date(a.date);
          const dateTwo = new Date(b.date);
          if (dateOne > dateTwo) {
            return 1;
          }
          return -1;
        });
    },
    getCustomAppointments: (state) => {
      return (start, end) => {
        return state.appointments
          .filter((appointment) => {
            const startDate = new Date(start);
            const endDate = new Date(end);
            const aptDate = new Date(appointment.date);
            return moment(aptDate).isBetween(startDate, endDate);
          })
          .sort((a, b) => {
            const dateOne = new Date(a.date);
            const dateTwo = new Date(b.date);
            if (dateOne > dateTwo) {
              return 1;
            }
            return -1;
          });
      };
    },
    getTomorrowsAppointments: (state) => {
      const today = new Date();
      return state.appointments
        .filter((appointment) => {
          const aptDate = new Date(appointment.date);
          return thisDay(aptDate) === nextDay(today);
        })
        .sort((a, b) => {
          const dateOne = new Date(a.date);
          const dateTwo = new Date(b.date);
          if (dateOne > dateTwo) {
            return 1;
          }
          return -1;
        });
    },
    getThisMonthsAppointments: (state) => {
      const today = new Date();
      return state.appointments
        .filter((appointment) => {
          const aptDate = new Date(appointment.date);
          return aptDate.getMonth() === today.getMonth() && aptDate.getFullYear() === today.getFullYear();
        })
        .sort((a, b) => {
          const dateOne = new Date(a.date);
          const dateTwo = new Date(b.date);
          if (dateOne > dateTwo) {
            return 1;
          }
          return -1;
        });
    },
    getLastMonthsAppointments: (state) => {
      const today = new Date();
      return state.appointments
        .filter((appointment) => {
          const aptDate = new Date(appointment.date);
          return aptDate.getMonth() === today.getMonth() - 1 && aptDate.getFullYear() === today.getFullYear();
        })
        .sort((a, b) => {
          const dateOne = new Date(a.date);
          const dateTwo = new Date(b.date);
          if (dateOne > dateTwo) {
            return 1;
          }
          return -1;
        });
    },
    getNextMonthsAppointments: (state) => {
      const today = new Date();
      return state.appointments
        .filter((appointment) => {
          const aptDate = new Date(appointment.date);
          return aptDate.getMonth() === today.getMonth() + 1 && aptDate.getFullYear() === today.getFullYear();
        })
        .sort((a, b) => {
          const dateOne = new Date(a.date);
          const dateTwo = new Date(b.date);
          if (dateOne > dateTwo) {
            return 1;
          }
          return -1;
        });
    },
    getUpcomingAppointments: (state) => {
      const today = new Date();
      return state.appointments
        .filter((appointment) => {
          const aptDate = new Date(appointment.date);
          return aptDate > today;
        })
        .sort((a, b) => {
          const dateOne = new Date(a.date);
          const dateTwo = new Date(b.date);
          if (dateOne > dateTwo) {
            return 1;
          }
          return -1;
        });
    },
    getCurrentAppointment: (state) => {
      return state.currentAppointment;
    },
    getNewPatientsThisMonth: (state) => {
      const today = new Date();
      return state.patients.filter((patient) => {
        const createdDate = new Date(patient.createdAt);
        return createdDate.getMonth() === today.getMonth() && createdDate.getFullYear() === today.getFullYear();
      });
    },
    getAvailableVendors: (state) => {
      return state.availableVendors;
    },
    getLocationId: (state) => {
      return state.id;
    },
    getSelectedCurrentLocationId() {
      return localStorage.currentLocation;
    },
    getCurrentLocationStatus: (state) => {
      return state.locationStatus;
    },
    getLocationContactInfo: (state) => {
      const payload = {
        name: state.name,
        address1: state.address1,
        address2: state.address2,
        city: state.city,
        region: state.region,
        postalCode: state.postalCode,
        email: state.email,
        phone: state.phone,
        isActive: state.isActive,
      };
      return payload;
    },
    getCurrentLocationOrder: (state) => {
      return state.currentOrder;
    },

    /** --------------- Patients Pagination ----------------------------- */
    getNumberOfPatientsPages(state, getters) {
      const pages = Math.ceil(getters.getCurrentLocationPatients.length / getters.getPageLength);
      if (pages === 0) {
        return 1;
      }
      return pages;
    },
    getNumberOfOrdersPages(state, getters) {
      const pages = Math.ceil(getters.getLocationOrders.length / getters.getPageLength);
      if (pages === 0) {
        return 1;
      }
      return pages;
    },
    getNumberOfProductsPages(state, getters) {
      const pages = Math.ceil(
        // getters.getLocationProductsList.length / getters.getPageLength,
        getters.getLocationOrders.length / getters.getPageLength
      );
      if (pages === 0) {
        return 1;
      }
      return pages;
    },
    getPageLength(state) {
      return state.pageLength;
    },
    getPage(state) {
      return state.page;
    },
    getLabPatientsPageResults(state, getters) {
      const page = getters.getPage;
      const results = getters.getCurrentLocationPatients;
      const pageLength = getters.getPageLength;

      const a = page * pageLength - pageLength;
      const b = a + pageLength;

      return results.slice(a, b);
    },
    getPageOrdersResults(state, getters) {
      const page = getters.getPage;
      const results = getters.getLocationOrders;
      const pageLength = getters.getPageLength;

      const a = page * pageLength - pageLength;
      const b = a + pageLength;

      return results.slice(a, b);
    },
    getPageProductsResults(state, getters) {
      const page = getters.getPage;
      // const results = getters.getLocationProductsList;
      const results = getters.getLocationOrders;
      const pageLength = getters.getPageLength;

      const a = page * pageLength - pageLength;
      const b = a + pageLength;

      return results.slice(a, b);
    },
    getCurrentSelectedLocation() {
      return localStorage.currentLocation;
    },
  },

  mutations: {
    setInventoryStock(state, payload) {
      state.inventoryStock = payload;
    },
    setPatientLocation(state, payload) {
      state.patientLocationId = payload.location.id;
    },
    setLocationPatients(state, payload) {
      state.patients = payload;
    },
    setPatientSearchResults(state, payload) {
      state.patientSearchResults = payload;
    },
    setLocationAppointments(state, payload) {
      state.appointments = payload;
    },
    setCurrentAppointment(state, payload) {
      state.currentAppointment.summary = payload;
    },
    setAvailableVendors(state, payload) {
      state.availableVendors = payload;
    },
    setLocationCardAccounts(state, payload) {
      state.paymentsCard = payload;
    },
    setLocationAchAccounts(state, payload) {
      state.paymentsAch = payload;
    },
    setLocationRegistrations(state, payload) {
      state.registrations = payload;
    },
    setLocationReceipts(state, payload) {
      state.receipts = payload;
    },
    setLocationContext(state, payload) {
      // LOCAL STORAGE SETTER
      localStorage.setItem("locationName", payload.name);
      localStorage.setItem("currentLocation", payload.locationId);
      localStorage.setItem("locationStatus", payload.locationStatus);
      // END SETTER
      state.locationStatus = payload.locationStatus;
      state.id = payload.locationId;
      state.selectedCurrentLocation = payload.locationId;
    },
    setLocationDetails(state, payload) {
      state.id = payload.id;
      state.name = payload.name;
      state.phone = payload.phone;
      state.email = payload.email;
      state.website = payload.website;
      state.address1 = payload.address1;
      state.address2 = payload.address2;
      state.city = payload.city;
      state.region = payload.region;
      state.postalCode = payload.postalCode;
      state.country = payload.country;
      state.addressId = payload.addressId;
      state.registrations = payload.registrations;
      state.isActive = payload.isActive;
    },
    resetLocationDetails(state) {
      state.locationStatus = false;
      state.id = "";
      state.name = "";
      state.phone = "";
      state.email = "";
      state.website = "";
      state.address1 = "";
      state.address2 = "";
      state.city = "";
      state.region = "";
      state.postalCode = "";
      state.country = "";
      state.addressId = "";
      state.paymentsAch = [];
      state.paymentsCard = [];
      state.availableVendors = [];
      state.registrations = [];
      state.receipts = [];
      state.patients = [];
      state.appointments = [];
      state.patientSearchResults = [];
      state.dosing = {
        details: {},
        users: [],
      };
      state.currentPatient = {
        info: {},
        labs: [],
        appointments: [],
      };
      state.currentAppointment = {
        summary: {},
        labs: {},
        dose: {
          info: {},
          calculated: false,
          sex: "",
          recommendedTest: "",
          recommendedEst: "",
        },
      };
      localStorage.removeItem("currentLocation");
    },
    setCurrentAppointmentLabs(state, payload) {
      state.currentAppointment.labs = payload;
    },
    setRecommendedMaleDose(state, payload) {
      state.currentAppointment.dose.info = payload;
      state.currentAppointment.dose.calculated = true;
      state.currentAppointment.dose.sex = "male";
      state.currentAppointment.dose.recommendedTest = payload.calculatedTestosterone;
      state.currentAppointment.dose.recommendedEst = "0";
    },
    setRecommendedFemaleDose(state, payload) {
      state.currentAppointment.dose.info = payload;
      state.currentAppointment.dose.calculated = true;
      state.currentAppointment.dose.sex = "female";
      state.currentAppointment.dose.recommendedTest = payload.calculatedTestosterone;
      state.currentAppointment.dose.recommendedEst = payload.calculatedEstradiol;
    },
    clearAppointmentDose(state) {
      state.currentAppointment.dose.info = {};
      state.currentAppointment.dose.calculated = false;
      state.currentAppointment.dose.sex = "";
      state.currentAppointment.dose.recommendedTest = "";
    },
    setLocationOrders(state, payload) {
      state.orders = payload;
    },
    setCurrentLocation(state, payload) {
      state.currentLocationStatus = payload;
    },
    setPage(state, payload) {
      state.page = payload;
    },
    setLocationProductList(state, payload) {
      state.products = payload;
    },
    setPurchaseReceipts(state, payload) {
      state.receipts = payload;
    },
  },

  actions: {
    fetchInventoryStock: async ({ commit }) => {
      const currentLocation = get(localStorage, "currentLocation", null);
      const response = await axios.get(`/orders/v1/location/${currentLocation}/inventoryStock`);
      commit("setInventoryStock", response.data);
    },
    updateBrandLogoAndFavicon: async ({ commit }, payload) => {
      const orgId = payload.id;
      const body = {
        name: payload.name,
        phone: payload.phone,
        fax: payload.fax,
        email: payload.email,
        website: payload.website,
        address1: payload.address1,
        address2: payload.address2,
        city: payload.city,
        region: payload.region,
        postalCode: payload.postalCode,
        country: payload.country,
        logoimgUrl: payload.logoimgUrl,
        favimgUrl: payload.favimgUrl,
      };
      const response = await axios.put(`/accounts/v1/org/${orgId}`, body);
      commit("accountsModule/setOrgDetails", response.data, { root: true });
    },
    // Get
    fetchLocationDirect: async ({ commit }, locationId) => {
      const response = await axios.get(`/accounts/v1/location/${locationId}`);
      commit("setLocationOnOrder", response.data);
    },
    fetchLocationDetails: async ({ commit, dispatch }, payload) => {
      const { orgId, locationId, returnResponse } = payload;
      const response = await axios.get(`/accounts/v1/org/${orgId}/location/${locationId}`);
      if (!returnResponse) {
        commit("setLocationDetails", response.data);
        dispatch("fetchLocationLoyaltyPoints");
      }
      return response.data;
    },
    fetchLocationDetailsById: async ({ commit, dispatch }, payload) => {
      const { locationId } = payload;
      const response = await axios.get(`/accounts/v1/location/${locationId}`);
      commit("setLocationDetails", response.data);
      dispatch("fetchLocationLoyaltyPoints");
    },
    fetchLocationAppointments: async ({ commit }, locationId) => {
      const response = await axios.get(`/patient/v1/location/${locationId}/appointment`);
      commit("setLocationAppointments", response.data);
    },
    fetchLocationRegistrations: async ({ commit }, payload) => {
      const { orgId } = payload;
      const { locationId } = payload;

      const response = await axios.get(`/accounts/v1/org/${orgId}/location/${locationId}/registration`);
      commit("setLocationRegistrations", response.data);
      return response.data;
    },
    // MEDCENTER API
    fetchMedcenterLocationPatients: async ({ commit }, locationId) => {
      const response = await axios.get(`/patient/v1/location/${locationId}/patient/medcenter`);
      commit("setLocationPatients", response.data);
    },
    // END......
    fetchLocationPatients: async ({ commit }, currentLocation) => {
      const response = await axios.get(`/patient/v1/location/${currentLocation}/patient`);
      commit("setLocationPatients", response.data);
    },
    fetchLocationPurchases: async ({ commit }, locationId) => {
      const response = await axios.get(`/orders/v1/buyer/${locationId}/purchase`);
      commit("setLocationOrders", response.data);
    },
    fetchLocationProducts: async ({ commit }, locationId) => {
      const response = await axios.get(`/orders/v1/buyer/${locationId}/uniqueProducts`);
      commit("setLocationOrders", response.data);
    },
    clearLocationPurchases: async ({ commit }) => {
      commit("setLocationOrders", []);
    },
    fetchAllVendors: async ({ commit }) => {
      const response = await axios.get("/accounts/v1/vendor");
      commit("setAvailableVendors", response.data);
    },
    fetchAllMarketVendors: async ({ commit }) => {
      const response = await axios.get("/accounts/v1/market/vendor");
      commit("setAvailableVendors", response.data);
    },
    fetchAllBuyerReceipts: async ({ commit }, buyerId) => {
      const response = await axios.get(`/orders/v1/buyer/${buyerId}/receipt`);

      commit("setLocationReceipts", response.data);
    },
    fetchBuyerReceipt: async ({ commit }, payload) => {
      const { buyerId } = payload;
      const { purchaseId } = payload;

      const response = await axios.get(`/orders/v1/buyer/${buyerId}/receipt/${purchaseId}`);
      commit("setPurchaseReceipts", response.data);
    },
    fetchAllCardAccounts: async ({ commit }, buyerId) => {
      const response = await axios.get(`/payments/v1/card/buyer/${buyerId}/method`);
      commit("setLocationCardAccounts", response.data);
    },
    fetchCardAccount: async (payload) => {
      const { buyerId } = payload;
      const { cardId } = payload;

      await axios.get(`/payments/v1/card/buyer/${buyerId}/method/${cardId}`);
    },
    fetchAllAchAccounts: async ({ getters, commit }, buyerId) => {
      const isFromVendor = window.location.href.indexOf("/vendor/") !== -1;
      const response = await axios.get(`/payments/v1/ach/buyer/${buyerId}/method`);
      // TODO: add distinction for org vs location
      if ((getters.getUserType === "ORG" || getters.getUserType === "orgUser") && !isFromVendor) {
        commit("setLocationAchAccounts", response.data);
      }
      if (getters.getUserType === "VENDOR" || isFromVendor) {
        commit("setVendorAchAccount", response.data);
      }
    },
    fetchAllAchTransactions: async (buyerId) => {
      await axios.get(`/payments/v1/ach/buyer/${buyerId}/transaction`);
    },
    fetchAchTransaction: async (payload) => {
      const { buyerId } = payload;
      const { transactionId } = payload;

      await axios.get(`/payments/v1/ach/buyer/${buyerId}/transaction/${transactionId}`);
    },

    // TODO: Figure out what these do
    fetchVendorReceipt: async (payload) => {
      const { vendorId } = payload;
      const { purchaseId } = payload;

      await axios.get(`/orders/v1/buyer/${vendorId}/receipt/${purchaseId}`);
    },
    fetchAllVendorReceipts: async (vendorId) => {
      await axios.get(`/orders/v1/buyer/${vendorId}/receipt`);
    },

    // Add
    addNewLocation: async ({ dispatch }, payload) => {
      const { orgId } = payload;
      const body = {
        name: payload.name,
        phone: payload.phone,
        fax: payload.fax,
        email: payload.email,
        website: payload.website,
        address1: payload.address1,
        address2: payload.address2,
        city: payload.city,
        region: payload.region,
        postalCode: payload.postalCode,
        country: payload.country,
      };
      await axios.post(`/accounts/v1/org/${orgId}/location`, body);
      dispatch("accountsModule/fetchOrgDetails", { orgId }, { root: true });
    },

    // If patient location dosn't exist
    addNewPatientLocation: async ({ commit }, payload) => {
      const body = {
        firstName: payload.firstName,
        lastName: payload.lastName,
        phone: payload.phone,
        email: payload.email,
        address1: payload.address1,
        address2: payload.address2,
        city: payload.city,
        region: payload.region,
        postalCode: payload.postalCode,
        country: payload.country,
        userId: payload.userId,
      };
      const response = await axios.put("/accounts/v1/patient/", body);
      commit("setPatientLocation", response.data);
    },
    addDeaLicense: async ({ dispatch }, payload) => {
      const { orgId } = payload;
      const { locationId } = payload;
      const body = {
        name: payload.name,
        expiration: payload.expiration,
        deaNumber: payload.deaNumber,
        holderName: payload.holderName,
        holderTitle: payload.holderTitle,
        fileLocation: payload.fileLocation,
        orgUserId: payload.orgUserId,
      };

      try {
        const response = await axios.post(`/accounts/v1/org/${orgId}/location/${locationId}/dea`, body);
        if (response) {
          notification("success", "DEA license added successfully!");
          dispatch("fetchLocationDetails", payload);
          return response;
        }
      } catch (err) {
        return null;
      }
    },
    deleteDeaLicense: async (_, payload) => {
      const { locationId } = payload;
      const { orgId } = payload;
      const { licenseId } = payload;
      await axios.delete(`/accounts/v1/org/${orgId}/location/${locationId}/dea/${licenseId}`);
    },
    addMedicalLicense: async ({ dispatch }, payload) => {
      const { orgId } = payload;
      const { locationId } = payload;
      const body = {
        name: payload.name,
        state: payload.state,
        expiration: payload.expiration,
        holderName: payload.holderName,
        holderTitle: payload.holderTitle,
        fileLocation: payload.fileLocation,
        orgUserId: payload.orgUserId,
        licenseNumber: payload.licenseNumber,
      };
      try {
        const response = await axios.post(`/accounts/v1/org/${orgId}/location/${locationId}/medical`, body);
        if (response) {
          notification("success", "Medical license added successfully!");
          dispatch("fetchLocationDetails", payload);
          return response;
        }
      } catch (error) {
        return null;
      }
    },

    addNPI: async ({ dispatch }, payload) => {
      const { orgId } = payload;
      const { locationId } = payload;
      const body = {
        fName: payload.fName,
        lName: payload.lName,
        npiNumber: payload.npiNumber,
        userId: payload.userId,
        orgUserId: payload.orgUserId,
      };
      try {
        const response = await axios.post(`/accounts/v1/org/${orgId}/location/${locationId}/npi`, body);
        if (response) {
          notification("success", "NPI added successfully!");
          dispatch("fetchLocationDetails", payload);
          return response;
        }
      } catch (error) {
        notification("warning", error.response.data.message);
      }
    },
    addVendorRegistration: async ({ dispatch }, payload) => {
      const { orgId } = payload;
      const { locationId } = payload;
      const body = {
        deaLicenseId: payload.deaLicenseId,
        medicalLicenseId: payload.medicalLicenseId,
        npiId: payload.npiId,
        vendorId: payload.vendorId,
      };
      try {
        const response = await axios.post(`/accounts/v1/org/${orgId}/location/${locationId}/registration`, body);
        dispatch("fetchLocationRegistrations", payload);
        notification("success", "Product successfully applied for registration.");
        return response;
      } catch (error) {
        notification("warning", error.response.data.message);
        return null;
      }
    },
    addNewAppointment: async ({ dispatch }, payload) => {
      const { locationId } = payload;
      const body = {
        patientId: payload.patientId,
        doctorId: payload.doctorId,
        type: payload.type,
        date: payload.date,
        status: "open",
      };
      await axios.post(`/patient/v1/location/${locationId}/appointment`, body);
      dispatch("fetchLocationAppointments", locationId);
    },
    addCardAccount: async ({ commit }, payload) => {
      const { locationId } = payload;
      const body = {
        cardholderName: payload.cardholderName,
        cardNumber: payload.cardNumber,
        expDate: payload.expDate,
        cardType: payload.cardType,
        billingAddress: payload.billingAddress,
        cvv2: payload.cvv2,
      };

      const response = await axios.post(`/payments/v1/card/buyer/${locationId}`, body);
      commit("setLocationCardAccounts", response.data);
    },
    addAchAccount: async ({ dispatch }, payload) => {
      const { buyerId } = payload;
      const body = {
        accountOwner: payload.accountOwner,
        bankName: payload.bankName,
        accountNumber: payload.accountNumber,
        routingNumber: payload.routingNumber,
        accountType: payload.accountType,
        billingAddress: payload.billingAddress,
      };

      await axios.post(`/payments/v1/ach/buyer/${buyerId}/method`, body);

      dispatch("fetchAllAchAccounts", buyerId);
    },
    updateAchPayoutAccount: async ({ dispatch }, payload) => {
      const { buyerId } = payload;
      const { achId } = payload;
      const body = {
        accountOwner: payload.accountOwner,
        bankName: payload.bankName,
        accountNumber: payload.accountNumber,
        routingNumber: payload.routingNumber,
        accountType: payload.accountType,
        billingAddress: payload.billingAddress,
      };

      await axios.put(`/payments/v1/ach/buyer/${buyerId}/ach/${achId}/method`, body);
      dispatch("fetchAllAchAccounts", buyerId);
    },

    addAchPayoutAccount: async ({ dispatch }, payload) => {
      const { buyerId } = payload;
      const body = {
        accountOwner: payload.accountOwner,
        bankName: payload.bankName,
        accountNumber: payload.accountNumber,
        routingNumber: payload.routingNumber,
        accountType: payload.accountType,
        billingAddress: payload.billingAddress,
      };

      await axios.post(`/payments/v1/ach/buyer/${buyerId}/method`, body);
      dispatch("fetchAllAchAccounts", buyerId);
    },

    // Update
    updateLocationContactInfo: async ({ commit }, payload) => {
      const { orgId } = payload;
      const { locationId } = payload;
      const body = {
        name: payload.name,
        phone: payload.phone,
        fax: payload.fax,
        email: payload.email,
        website: payload.website,
        address1: payload.address1,
        address2: payload.address2,
        city: payload.city,
        region: payload.region,
        postalCode: payload.postalCode,
        country: payload.country,
      };
      const response = await axios.put(`accounts/v1/org/${orgId}/location/${locationId}`, body);
      commit("setLocationDetails", response.data);
    },

    // Delete
    deleteLocation: async (payload) => {
      const { orgId } = payload;
      const { locationId } = payload;
      await axios.delete(`/accounts/v1/org/${orgId}/location/${locationId}`);
      // TODO: commit
    },
    deleteNPI: async (_, payload) => {
      const { orgId } = payload;
      const { locationId } = payload;
      const { npiId } = payload;
      await axios.delete(`/accounts/v1/org/${orgId}/location/${locationId}/npi/${npiId}`);
    },
    deleteMedicalLicense: async (_, payload) => {
      const { orgId } = payload;
      const { locationId } = payload;
      const { licenseId } = payload;

      const response = await axios.delete(`/accounts/v1/org/${orgId}/location/${locationId}/medical/${licenseId}`);
      if (response) {
        notification("success", "Medical license deleted successfully!");
        return payload;
      }
    },
    deleteCardAccount: async (_, payload) => {
      const { buyerId } = payload;
      const { accountId } = payload;

      const response = await axios.delete(`/payments/v1/card/buyer/${buyerId}/method/${accountId}`);
      if (response) {
        return response;
      }
      return null;
    },
    deleteAchAccount: async ({ dispatch }, payload) => {
      const { buyerId } = payload;
      const { accountId } = payload;

      await axios.delete(`/payments/v1/ach/buyer/${buyerId}/method/${accountId}`);
      dispatch("fetchAllAchAccounts", buyerId);
    },

    searchLocationPatients: async ({ commit }, payload) => {
      const { locationId, searchQuery } = payload;
      const response = await axios.get(`/patient/v1/location/${locationId}/patient/search?q=${searchQuery}`);
      commit("setPatientSearchResults", response.data);
    },
    resetLocationDetails: ({ commit }) => {
      commit("resetLocationDetails");
    },
    toggleLocationContext: ({ commit }, payload) => {
      commit("setLocationContext", payload);
    },

    changeLocation: async ({ dispatch }, { locationId, isActive }) => {
      const orgId = localStorage.getItem("orgId");
      await dispatch("fetchLocationDetails", { orgId, locationId });
      await dispatch("toggleLocationContext", { locationId, locationStatus: isActive });
      dispatch("fetchActiveCart", locationId);
      dispatch("fetchAllCardAccounts", locationId);
      dispatch("fetchLocationLoyaltyPoints");
    },

    /** --------------- Patients Pagination ----------------------------- */
    setCurrentLocation: ({ commit }, payload) => {
      commit("setCurrentLocation", payload);
    },
    nextPage({ getters, commit }) {
      commit("setPage", getters.getPage + 1);
    },
    prevPage({ getters, commit }) {
      commit("setPage", getters.getPage - 1);
    },
    commitPage({ commit }, payload) {
      commit("setPage", payload);
    },
    setProductList: ({ commit }, payload) => {
      commit("setLocationProductList", payload);
    },
  },
};
