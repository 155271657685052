const defaultPaginationDetails = {
  currentPage: 0,
  totalPages: 0,
  pageSize: 0,
  totalData: 0,
};

export default {
  vendorUsers: {
    data: [],
    paginationDetails: defaultPaginationDetails,
  },
  laboratories: [],
  vendorShipping: []
};
