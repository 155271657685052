const defaultPaginationDetails = {
  currentPage: 0,
  totalPages: 0,
  pageSize: 0,
  totalData: 0,
};

export default {
  orgDetails: null,
  orgDosingDetails: null,
  orgLocations: [],
  orgDosingUsers: [],
  orgUserLookup: [],
  orgList: {
    data: [],
    paginationDetails: defaultPaginationDetails,
  },
  orgUsers: {
    data: [],
    paginationDetails: defaultPaginationDetails,
  },
  orgContacts: [],
  orgAssessments: [],
  deaLicenses: [],
  medicalLicenses: [],
  npi: [],
};
