import GetSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/get";
import PutSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/put";

const fetchOrgAnalytics = async ({ commit }, orgId) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = `/directory/v1/analytics/org/${orgId}`;
  const response = await proxy.execute(query);
  if (response) {
    const { data } = response;
    commit("setOrgAnalytics", data);
    return data;
  }
};

const fetchOrgLandingPage = async ({ commit }, orgId) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = `/directory/v1/analytics/org/${orgId}/landing`;
  const response = await proxy.execute(query);
  if (response) {
    const { data } = response;
    commit("setLandingPageResults", data);
    return data;
  }
};

// fetchLandingPageViewByOrg: async ({ commit, getters }) => {
//   const response = await axios.get(`/directory/v1/analytics/org/${getters.getOrgId}/landing`);

//   commit("setLandingPageResults", response.data);
// },

// const updateDirectoryProfile = async ({ commit }, payload) => {
//   const proxy = new PutSimpatraServiceProxy();
//   const query = `directory/v1/provider/id/${payload.id}`;
//   const response = await proxy.execute(query, payload);
//   if (response) {
//     const { data } = response;
//     return data;
//   }
// };

export default {
  fetchOrgAnalytics,
  fetchOrgLandingPage,
};
