import { isEmpty } from "lodash";
import axios from "@/lib/axios";
import notification from "@/utils/notifications";

export default {
  namespaced: true,
  state: {
    registrations: [],
  },

  getters: {
    getRegistrations: (state) => {
      return state.registrations.sort((a, b) => {
        if (a.createdAt > b.createdAt) {
          return -1;
        }
        if (a.createdAt < b.createdAt) {
          return 1;
        }
        return 0;
      });
    },
    getRegistrationsByVendorId: (state, getters, rootState, rootGetters) => {
      return state.registrations.filter((registration) => {
        return registration.vendor.id === rootGetters["marketplaceVendor/getVendor"].id;
      });
    },
  },

  mutations: {
    setRegistrations(state, payload) {
      state.registrations = payload;
    },
  },

  actions: {
    fetchRegistrations: async ({ commit }) => {
      const orgId = localStorage.getItem("orgId");
      const locationId = localStorage.getItem("currentLocation");
      try {
        const response = await axios.get(`/accounts/v1/org/${orgId}/location/${locationId}/registration`);
        commit("setRegistrations", response.data);
      } catch (error) {
        notification("warning", error);
      }
    },
    getVendorApprovedRegistrationStatus: ({ getters }, vendorId) => {
      const currentLocation = localStorage.getItem("currentLocation");
      const vendorRegistration = getters.getRegistrations.find((registration) => {
        return registration.vendor.id === vendorId && registration.location.id === currentLocation;
      });
      return !isEmpty(vendorRegistration) && vendorRegistration && vendorRegistration.status === "APPROVED";
    },
  },
};
