import GetSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/get";

const fetchSuperAdminPermissionDetails = async (_, userId) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = `accounts/v1/superadmin/permission/${userId}`;
  const response = await proxy.execute(query);
  if (response) {
    const { data } = response;
    return data;
  }
};

export default {
  fetchSuperAdminPermissionDetails,
};
