import axios from '../lib/axios';

export default {
  state: {
    currentConsult: {},
    consults: [],
  },

  getters: {
    getConsults: (state) => { return state.consults; },
    getCurrentConsult: (state) => { return state.currentConsult; },
  },

  mutations: {
    setCurrentConsult(state, payload) {
      state.currentConsult = payload;
    },
    setConsults(state, payload) {
      state.consults = payload;
    },
  },

  actions: {
    // Get
    fetchAllConsults: async ({ commit }, providerId) => {
      const response = await axios.get(
        `/consult/v1/provider/${providerId}/consult`,
      );
      commit('setConsults', response.data);
    },
    fetchAllConsultantConsults: async ({ commit }, consultantId) => {
      const response = await axios.get(
        `/consult/v1/consultant/${consultantId}/consult`,
      );
      commit('setConsults', response.data);
    },

    // Create
    createNewConsult: async ({ commit, dispatch }, payload) => {
      const { providerId, message, patientId } = payload;
      const body = { patientId, message };
      const response = await axios.post(
        `/consult/v1/provider/${providerId}/consult`,
        body,
      );
      commit('setCurrentConsult', response.data);
      dispatch('fetchAllConsults', providerId);
    },
    addMessageToConsult: async ({ commit }, payload) => {
      const { consultId, providerId, message } = payload;
      const body = { message };
      const response = await axios.post(
        `/consult/v1/provider/${providerId}/consult/${consultId}`,
        body,
      );
      commit('setCurrentConsult', response.data);
    },
    addMessageToConsultantConsult: async ({ commit }, payload) => {
      const { consultId, consultantId, message } = payload;
      const body = { message };
      const response = await axios.post(
        `/consult/v1/consultant/${consultantId}/consult/${consultId}`,
        body,
      );
      commit('setCurrentConsult', response.data);
    },
    completeConsult: async ({ commit }, payload) => {
      const { consultId, providerId } = payload;
      const response = await axios.post(
        `/consult/v1/provider/${providerId}/consult/${consultId}/close`,
        {},
      );
      commit('setCurrentConsult', response.data);
    },

    // Commits
    commitCurrentConsult: async ({ commit }, payload) => {
      commit('setCurrentConsult', payload);
    },
    clearConsultState: async ({ commit }) => {
      commit('setCurrentConsult', {});
      commit('setPatientLabs', []);
      commit('setPatientAppointments', []);
    },

  },
};
