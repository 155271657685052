import axios from "@/lib/axios";
import notification from "@/utils/notifications";

const addIntellipelPatient = async ({ commit }, payload) => {
  try {
    const { locationId } = payload;
    const body = {
      firstName: payload.firstName,
      lastName: payload.lastName,
      sex: payload.sex,
      dob: payload.dob,
      socialSecurity: payload.socialSecurity,
      heightInches: payload.heightInches,
      weightPounds: payload.weightPounds,
      ethnicity: payload.ethnicity,
    };
    const response = await axios.post(`/patient/v1/location/${locationId}/patient`, body);
    if (response) {
      const { data } = response;
      commit("updateIntellipelPatients", data);
    }
    notification("success", "Successfully added intellipel patient.");
  } catch (error) {
    notification("warning", error.response.data.message);
  }
};

export default {
  addIntellipelPatient,
};
