import GetSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/get";
import PutSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/put";
import PostSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/post";
import DeleteSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/delete";

const fetchLocationDetails = async ({ commit }, locationId) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = `accounts/v1/location/${locationId}`;
  const response = await proxy.execute(query);
  if (response) {
    const { data } = response;
    return data;
  }
};
const fetchLocationSignature = async (_, locationId) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = `accounts/v1/location/${locationId}/signature`;
  const response = await proxy.execute(query);
  if (response) {
    const { data } = response;
    return data;
  }
};

const addLocationSignature = async (_, { locationId, payload }) => {
  const proxy = new PostSimpatraServiceProxy();
  const query = `accounts/v1/location/${locationId}/signature`;
  const response = await proxy.execute(query, payload, {
    notificationParams: {
      displaySuccessMessage: true,
      successNotificationMsg: "Succesfully Added Signature!",
    },
  });
  if (response) {
    const { data } = response;
    return data;
  }
};

const updateLocationSignature = async (_, { locationId, signatureId, payload }) => {
  const proxy = new PutSimpatraServiceProxy();
  let query = `accounts/v1/location/${locationId}/signature/${signatureId}`;
  const notifMsg = `Successfully updated signature!`;
  const response = await proxy.execute(query, payload, notifMsg);
  if (response) {
    const { data } = response;
    return data;
  }
};

const deleteLocationSignature = async (_, { locationId, signatureId }) => {
  const proxy = new DeleteSimpatraServiceProxy();
  const query = `accounts/v1/location/${locationId}/signature/${signatureId}`;
  return await proxy.execute(query, {
    notificationParams: {
      displaySuccessMessage: true,
      successNotificationMsg: "Successfully deleted signature!",
    },
  });
};

export default {
  fetchLocationDetails,
  fetchLocationSignature,
  addLocationSignature,
  updateLocationSignature,
  deleteLocationSignature,
};
