import GetSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/get";
import PutSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/put";
import PostSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/post";

const requestResetPassword = async ({ commit }, { body }) => {
  const proxy = new PostSimpatraServiceProxy();
  let query = `j-idp/v1/reset-password`;
  const response = await proxy.execute(query, body, {});
  if (response) {
    return response;
  }
};

const validatePasswordToken = async (_, confirmationToken) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = `j-idp/v1/reset-password/validate-token?token=${confirmationToken}`;
  const response = await proxy.execute(query);
  if (response) {
    const { data } = response;
    return data;
  }
};

const updatePasswordWithToken = async (_, { confirmationToken, body }) => {
  const proxy = new PutSimpatraServiceProxy();
  let query = `j-idp/v1/reset-password?token=${confirmationToken}`;
  const response = await proxy.execute(query, body);
  if (response) {
    const { data } = response;
    return data;
  }
};

export default {
  requestResetPassword,
  validatePasswordToken,
  updatePasswordWithToken,
};