const updateOrgListUsers = (state, payload) => {
  const orgIndex = state.orgList.data.findIndex((org) => org.id === payload.currentOrgId);
  const currentOrg = state.orgList.data[orgIndex];
  if (currentOrg.id !== payload.orgId && orgIndex >= 0) {
    payload.userIdList.map((id) => {
      const userIndex = currentOrg.orgUsers.findIndex((user) => user.id === id);
      if (userIndex >= 0) currentOrg.orgUsers.splice(userIndex, 1);
    });
  }
};

export default {
  updateOrgListUsers,
};
