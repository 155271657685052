import GetSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/get";

const fetchVendorByVin = async ({ commit }, vendorId) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = `accounts/v1/market/vendor/vin/${vendorId}`;
  const response = await proxy.execute(query);
  if (response) {
    const { data } = response;
    commit("setSelectedProductVendor", data);
    return data;
  }
};

export default {
  fetchVendorByVin,
};
