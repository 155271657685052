import GetSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/get";
import npiModuleActions from "./npiModule/actions";
import orgModuleActions from "./orgModule/actions";
import vendorModuleActions from "./vendorModule/actions";
import locationModuleActions from "./locationModule/actions";
import superAdminModuleActions from "./superAdminModule/actions";
import marketModuleActions from "./marketModule/actions";
import passswordModuleActions from "./passwordModule/actions";

const fetchPatientAccountDetailsById = async (_, patientId) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = `accounts/v1/patient/${patientId}`;
  const response = await proxy.execute(query);
  if (response) {
    const { data } = response;
    return data;
  }
};

export default {
  fetchPatientAccountDetailsById,
  ...npiModuleActions,
  ...orgModuleActions,
  ...vendorModuleActions,
  ...locationModuleActions,
  ...superAdminModuleActions,
  ...marketModuleActions,
  ...passswordModuleActions,
};
