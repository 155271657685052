import { get } from "lodash";
import GetSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/get";

const fetchOrgLocationsListLookup = async (_, queryParams) => {
  const proxy = new GetSimpatraServiceProxy();
  // query params = (isActive, orgId)
  let query = `accounts/v1/location/orders`;
  const response = await proxy.execute(query, queryParams);
  if (response) {
    return get(response, "data", null);
  }
  return null;
};

const fetchOrgUsersListLookup = async (_, { orgId, queryParams }) => {
  const proxy = new GetSimpatraServiceProxy();
  let query = `accounts/v1/org/${orgId}/lookup/user`;
  const response = await proxy.execute(query, queryParams);
  if (response) {
    return get(response, "data", null);
  }
  return [];
};

export default {
  fetchOrgLocationsListLookup,
  fetchOrgUsersListLookup,
};
