export default {
  namespaced: true,
  state: {
    // pageLoaded: false,
    sessionExpired: false,
  },
  getters: {
    // getPageLoader(state) {
    //   return true; //state.pageLoaded;
    // },
    getSessionExpired(state) {
      return state.sessionExpired;
    },
  },
  mutations: {
    // setPageLoader(state, payload) {
    //   state.pageLoaded = true; //payload;
    // },
    setSessionExpired(state, payload) {
      state.sessionExpired = payload;
    },
  },
  actions: {
    // setPageLoader({ commit }, payload) {
    //   commit("setPageLoader", payload);
    // },
    setSessionExpired({ commit }, payload) {
      commit("setSessionExpired", payload);
    },
  },
};
