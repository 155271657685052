import axios from '../lib/axios';

export default {
  namespaced: true,
  state: {
    landingPageData: [],
    templateSettings: {
    },
    bhrtColorCodes: {},
    domainName: '',
    seletedTemplateIndex: Number,

    // for lab test
    labTestLandingPageData: [],
    labTestTemplateSettings: {
      heroImageIndexSelected: Number,
    },
    contents: [{ name: '', content: [] }],
    labTestColorCodes: {},
    labTestDomainName: '',
    labTestseletedTemplateIndex: Number,
    heroImages: [
      {
        location: 'https://simpatra-img.s3.us-west-2.amazonaws.com/product/1621421732360-dentist-02.jpg',
        isSelected: false,
        isDefault: true,
      },
      {
        location: 'https://simpatra-img.s3.us-west-2.amazonaws.com/product/1621421841950-scinta-bg.jpg',
        isSelected: true,
        isDefault: true,
      },
    ],
    contactImages: [
      {
        location: 'https://simpatra-img.s3.us-west-2.amazonaws.com/product/1621422147995-kv-contact-us.jpg',
        isSelected: false,
        isDefault: true,
      },
      {
        location: 'https://simpatra-img.s3.us-west-2.amazonaws.com/product/1621504831429-start-your-journey.jpg',
        isSelected: true,
        isDefault: true,
      },
    ],
    generalImages: [
      {
        location: 'https://simpatra-img.s3.us-west-2.amazonaws.com/product/1621422052325-symptoms.jpg',
        isSelected: true,
        isDefault: true,
        isFirstTemplate: true,
      },
      {
        location: 'https://simpatra-img.s3.us-west-2.amazonaws.com/product/1621422092995-Healthcare_ManWoman.png',
        isSelected: false,
        isDefault: true,
        isFirstTemplate: false,
      },
    ],
    socialMedia: {},
    socialMediaList: '',
    socialMediaFile: '',
  },

  getters: {
    getTemplateImages: (state) => { return state.tempateImages; },
    getHeroImages: (state) => { return state.heroImages; },
    getContactImages: (state) => {
      return state.contactImages;
    },
    getGeneralImages: (state) => { return state.generalImages; },

    getStoreProducts: (state) => { return state.storeProducts; },
    getLandingPagesData: (state) => { return state.landingPageData; },
    getTemplateSettings: (state) => { return state.templateSettings; },

    getColorCodes: (state) => { return state.bhrtColorCodes; },

    getContents: (state) => { return state.templateSettings.contents; },

    getDomainName: (state) => { return state.domainName; },

    getSeletedTemplateIndex: (state) => { return state.seletedTemplateIndex; },

    // for Lab Test
    getLabTestLandingPageData: (state) => { return state.labTestLandingPageData; },
    getLabTestTemplateSettings: (state) => { return state.labTestTemplateSettings; },
    getLabTestDomainName: (state) => { return state.labTestDomainName; },
    getLabTestseletedTemplateIndex: (state) => { return state.labTestseletedTemplateIndex; },
    getLabTestContents: (state) => { return state.contents; },
    getLabTestColorCodes: (state) => { return state.labTestColorCodes; },
    getSocialMedia: (state) => { return state.socialMedia; },
    getSocialMediaList: (state) => { return state.socialMediaList; },
    getSocialMediaFile: (state) => { return state.socialMediaFile; },
  },

  mutations: {
    setLandingPageData(state, payload) {
      state.landingPageData = payload;
    },

    setTemplateSettings(state, payload) {
      state.templateSettings = payload;
    },

    setDomainName(state, payload) {
      state.DomainName = payload;
    },

    setColorCodes(state, colorCodes) {
      state.bhrtColorCodes = colorCodes;
    },

    setContents(state, contentsArray) {
      state.templateSettings.contents = contentsArray;
    },

    setSeletedTemplateIndex(state, seletedTemplateIndex) {
      state.seletedTemplateIndex = seletedTemplateIndex;
    },

    // for Lab Test
    setLabTestLandingPageData(state, payload) {
      state.labTestLandingPageData = payload;
    },

    setLabTestTemplateSettings(state, payload) {
      state.labTestTemplateSettings = payload;
    },

    setLabTestDomainName(state, payload) {
      state.labTestDomainName = payload;
    },

    setLabTestseletedTemplateIndex(state, payload) {
      state.labTestseletedTemplateIndex = payload;
    },

    setLabTestContents(state, contentsArray) {
      state.contents = contentsArray;
    },
    setLabTestColorCodes(state, colorCodes) {
      state.labTestColorCodes = colorCodes;
    },
    setTemplateImages(state, payload) {
      state.tempateImages = payload;
    },
    setHeroImages(state, payload) {
      state.heroImages = payload;
    },
    setContactImages(state, payload) {
      state.contactImages = payload;
    },
    setGeneralImages(state, payload) {
      state.generalImages = payload;
    },
    setSocialMedia(state, payload) {
      state.socialMedia = payload;
    },
    setSocialMediaList(state, payload) {
      state.socialMediaList = payload;
    },
    setSocialMediaFile(state, payload) {
      state.socialMediaFile = payload;
    },
  },

  actions: {
    addHeroImagesToLocal: async ({ commit }, payload) => {
      commit('setHeroImages', payload);
    },
    addContactImagesToLocal: async ({ commit }, payload) => {
      commit('setContactImages', payload);
    },
    addGeneralImagesToLocal: async ({ commit }, payload) => {
      commit('setGeneralImages', payload);
    },
    commitTemplateSettings: async ({ commit }, templateSettings) => {
      await commit('setTemplateSettings', templateSettings);
    },
    commitColorCodes: async ({ commit }, colorCodes) => {
      commit('setColorCodes', colorCodes);
    },
    commitContents: async ({ commit }, contents) => {
      commit('setContents', contents);
    },
    commitDomainName: async ({ commit }, domainName) => {
      commit('setDomainName', domainName);
    },
    commitSeletedTemplateIndex: async ({ commit }, seletedTemplateIndex) => {
      commit('setSeletedTemplateIndex', seletedTemplateIndex);
    },

    // for Lab Test
    commitLabTestLandingPageData: async ({ commit }, labTestLandingPageData) => {
      await commit('setLabTestLandingPageData', labTestLandingPageData);
    },

    commitLabTestTemplateSettings: async ({ commit }, labTestTemplateSettings) => {
      commit('setLabTestTemplateSettings', labTestTemplateSettings);
    },

    commitLabTestDomainName: async ({ commit }, labTestDomainName) => {
      commit('setLabTestDomainName', labTestDomainName);
    },

    commitLabTestseletedTemplateIndex: async ({ commit }, labTestseletedTemplateIndex) => {
      commit('setLabTestseletedTemplateIndex', labTestseletedTemplateIndex);
    },

    commitLabTestContents: async ({ commit }, contents) => {
      commit('setLabTestContents', contents);
    },

    commitLabTestColorCodes: async ({ commit }, colorCodes) => {
      commit('setLabTestColorCodes', colorCodes);
    },

    // POST API
    addLandingPageData: async ({ commit, dispatch }, data) => {
      const payload = data.data;
      const body = {
        orgId: payload.orgId,
        domainName: payload.domainName,
        pageId: payload.pageId,
        settings: JSON.stringify(payload.settings),
      };

      const response = await axios.post(
        `/accounts/v1/landing/org/${payload.orgId}`,
        body,
      );
      dispatch('fetchLandingPageData', data.data);
      return response;
    },

    // GET API
    fetchLandingPageData: async ({ commit, dispatch }, payload) => {
      const { orgId } = payload;
      const response = await axios.get(
        `accounts/v1/landing/org/${orgId}`,
      );
      if (response.data !== undefined && response.data !== '' && response.data.length > 0) {
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].domainName.includes('bhrt.health')) {
            commit('setLandingPageData', response.data[i]);
            const settingsJsonObject = JSON.parse(response.data[i].settings);
            commit('setColorCodes', settingsJsonObject.colorCodes);
            commit('setContents', settingsJsonObject.contents);
            commit('setTemplateSettings', settingsJsonObject);
            commit('setSeletedTemplateIndex', Number(response.data[i].pageId));
            if (settingsJsonObject.heroImages) {
              commit('setHeroImages', settingsJsonObject.heroImages);
              commit('setGeneralImages', settingsJsonObject.generalImages);
              commit('setContactImages', settingsJsonObject.contactImages);
            } else {
              dispatch('updateDefaultImages');
            }
          }
          if (response.data[i].domainName.includes('labtest.health')) {
            commit('setLabTestLandingPageData', response.data[i]);
            const settingsJsonObjectLab = JSON.parse(response.data[i].settings);
            commit('setLabTestColorCodes', settingsJsonObjectLab.colorCodes);
            commit('setLabTestContents', settingsJsonObjectLab.contents);
            commit('setLabTestTemplateSettings', settingsJsonObjectLab);
            commit('setLabTestseletedTemplateIndex', Number(response.data[i].pageId));
          }
        }
      } else {
        const settings = {
          // Amit changes
          heroImageIndexSelected: 1,
          generalSymptomsImageIndexSelected: 1,
          contactUsImageIndexSelected: 1,
          // domainName: '',
          orgId: payload.orgId,
          pageId: 1,
          colorCodes: {
            practiceInformationColorCode: '#562778',
            practiceInformationButtonCode: '#887db3',
            bhrtInformationBarColorCode: '#562778',
            meetTheTeamColorCode: '#887db3',
            generalSymptomsColorCode: '#374458',
            generalSymptomsSubTextCode: '#562778',
            ourServicesColorCode: '#b4b0bf',
            generalSymptomsBGColorCode: '#f6faff',
            contactUsBGColorCode: '#ffffff',
            contactUsColorCode: '#374458',
          },
          pageTitle: 'Practice Builder',
          contents: [
            {
              id: 0,
              title: 'HeroContent',
              content: 'We’re all about optimizing your health and hormones. Start your journey to vitality today with our unique functional and lifestyle medicine program.',
            },
            {
              id: 1,
              title: 'WhatisBHRT?',
              content: 'Bioidentical Hormone Replacement Therapy utilizes biodentical hormones that are man-made hormones that are chemically similar to hormones produced by humans.',
            },
            {
              id: 2,
              title: 'PelletTherapy',
              content: 'Pellets are compounded hormones that are derived from organic plant materials, that are chemically similar to hormones produces by humans. They are about the size of a cooked grain of rice.',
            },
            {
              id: 3,
              title: 'Procedure',
              content: 'A provider will subcutaneously place hormone pellets in your skin. This placement of hormones allows patients to receive a steady flow of hormones 24/7, which helps to avoid side effects.',
            },
          ],
          heroImages: [
            {
              location: 'https://simpatra-img.s3.us-west-2.amazonaws.com/product/1621421732360-dentist-02.jpg',
              isSelected: false,
              isDefault: true,
            },
            {
              location: 'https://simpatra-img.s3.us-west-2.amazonaws.com/product/1621421841950-scinta-bg.jpg',
              isSelected: true,
              isDefault: true,
            },
          ],
          contactImages: [
            {
              location: 'https://simpatra-img.s3.us-west-2.amazonaws.com/product/1621422147995-kv-contact-us.jpg',
              isSelected: false,
              isDefault: true,
            },
            {
              location: 'https://simpatra-img.s3.us-west-2.amazonaws.com/product/1621504831429-start-your-journey.jpg',
              isSelected: true,
              isDefault: true,
            },
          ],
          generalImages: [
            {
              location: 'https://simpatra-img.s3.us-west-2.amazonaws.com/product/1621422052325-symptoms.jpg',
              isSelected: true,
              isDefault: true,
              isFirstTemplate: true,
            },
            {
              location: 'https://simpatra-img.s3.us-west-2.amazonaws.com/product/1621422092995-Healthcare_ManWoman.png',
              isSelected: false,
              isDefault: true,
              isFirstTemplate: false,
            },
          ],
        };
        commit('setLandingPageData', []);
        commit('setColorCodes', settings.colorCodes);
        commit('setContents', settings.contents);
        commit('setTemplateSettings', settings);
        commit('setSeletedTemplateIndex', 0);
        commit('setHeroImages', settings.heroImages);
        commit('setGeneralImages', settings.generalImages);
        commit('setContactImages', settings.contactImages);

        // commit('setColorCodes', []);
        // commit('setContents', {});
        // commit('setTemplateSettings', {});
        // commit('setSeletedTemplateIndex', 1);
      }
    },
    updateDefaultImages: async ({ commit }) => {
      const settings = {
        heroImages: [
          {
            location: 'https://simpatra-img.s3.us-west-2.amazonaws.com/product/1621421732360-dentist-02.jpg',
            isSelected: false,
            isDefault: true,
          },
          {
            location: 'https://simpatra-img.s3.us-west-2.amazonaws.com/product/1621421841950-scinta-bg.jpg',
            isSelected: true,
            isDefault: true,
          },
        ],
        contactImages: [
          {
            location: 'https://simpatra-img.s3.us-west-2.amazonaws.com/product/1621422147995-kv-contact-us.jpg',
            isSelected: false,
            isDefault: false,
          },
          {
            location: 'https://simpatra-img.s3.us-west-2.amazonaws.com/product/1621504831429-start-your-journey.jpg',
            isSelected: true,
            isDefault: true,
          },
        ],
        generalImages: [
          {
            location: 'https://simpatra-img.s3.us-west-2.amazonaws.com/product/1621422052325-symptoms.jpg',
            isSelected: true,
            isDefault: true,
            isFirstTemplate: true,
          },
          {
            location: 'https://simpatra-img.s3.us-west-2.amazonaws.com/product/1621422092995-Healthcare_ManWoman.png',
            isSelected: false,
            isDefault: true,
            isFirstTemplate: false,
          },
        ],
      };
      commit('setHeroImages', settings.heroImages);
      commit('setGeneralImages', settings.generalImages);
      commit('setContactImages', settings.contactImages);
    },
    // PUT API
    updateLandingPageData: async ({ commit, dispatch }, data) => {
      const payload = data.data;
      const body = {
        orgId: payload.orgId,
        domainName: payload.domainName,
        pageId: payload.pageId,
        settings: JSON.stringify(payload.settings),
      };
      const response = await axios.put(
        `/accounts/v1/landing/org/${payload.orgId}/update/${data.id}`,
        body,
      );
      return response;
    },
    postSocialMedia: async ({ commit }, payload) => {
      const body = {
        title: payload.title,
        imageUrl: payload.imageUrl,
        contentData: JSON.stringify(payload.content),
        createdBy: payload.userId,
      };
      const response = await axios.post(
        '/accounts/v1/outreach/socialmedia', body,
      );

      commit('setSocialMedia', response.data);
    },
    fetchSocialMediaList: async ({ commit }, payload) => {
      const { userId } = payload;
      const response = await axios.get(
        `accounts/v1/outreach/socialmedia/${userId}`,
      );
      const newResponse = [];
      response.data.forEach((element) => {
        element.content_data = element.content_data.length > 0 ? JSON.parse(element.content_data) : [];
        newResponse.push(element);
      });

      newResponse.sort((a, b) => { return new Date(a.created_at) - new Date(b.created_at); });

      commit('setSocialMediaList', newResponse);
    },
    fetchS3BucketFile: async ({ commit }, payload) => {
      const { fileName } = payload;
      const { repository } = payload;
      const response = await axios.get(
        `accounts/v1/outreach/download/${repository}/${fileName}`,
      );

      commit('setSocialMediaFile', response.data);
    },
    fetchAllSocialMediaList: async ({ commit }) => {
      const response = await axios.get(
        'accounts/v1/outreach/fetchallsocialmedia',
      );
      const newResponse = [];
      await response.data.forEach((element) => {
        element.content_data = element.content_data.length > 0 ? JSON.parse(element.content_data) : [];
        newResponse.push(element);
      });

      commit('setSocialMediaList', newResponse);
    },
    updateSocialMedia: async ({ commit }, payload) => {
      const { userId } = payload;
      const body = {
        title: payload.title,
        imageUrl: payload.imageUrl,
        contentData: JSON.stringify(payload.content),
        updatedBy: payload.updatedBy,
      };
      const response = await axios.post(
        `/accounts/v1/outreach/updatesocialmedia/${userId}`, body,
      );

      commit('setSocialMedia', response.data);
    },
    deleteSocialMedia: async ({ commit }, payload) => {
      const { id } = payload;
      const body = {
        isDeleted: payload.isDeleted,
      };
      const response = await axios.post(
        `/accounts/v1/outreach/deletesocialmedia/${id}`, body,
      );

      commit('setSocialMedia', response.data);
    },

  },
};
