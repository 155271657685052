import GetSimpatraLabResultProxy from "@/proxies/back-end/simpatra-lab-result/get";

const fetchLabResult = async (_, activationCode) => {
  const proxy = new GetSimpatraLabResultProxy();
  const query = `patient/${activationCode}/${process.env.VUE_APP_LAB_FILE_TO_INTEGRATION}`;
  const response = proxy.execute(query);
  return response;
};

export default {
  fetchLabResult,
};
