import axios from '../lib/axios';
// TODO; Refactor into current appointment
export default {
  state: {
    currentInsertion: {},
    detail: {},
  },

  getters: {
    getCurrentInsertion: (state) => { return state.currentInsertion; },
    getPatientDetail: (state) => {
      return state.detail;
    },
  },

  mutations: {
    setCurrentInsertion(state, payload) {
      state.currentInsertion = payload;
    },
    setPatientDetail(state, payload) {
      state.detail = payload;
    },
  },

  actions: {
    // Fetch
    fetchAppointmentLabs: async ({ commit }, payload) => {
      const { locationId, patientId } = payload;
      const response = await axios.get(
        `/patient/v1/location/${locationId}/patient/${patientId}/lab`,
      );
      commit('setCurrentAppointmentLabs', response.data);
    },
    fetchAppointmentDose: async ({ commit }, payload) => {
      const { locationId, patientId, appointmentId } = payload;
      const response = await axios.get(
        `/dosing/v1/location/${locationId}/patient/${patientId}/appointment/${appointmentId}`,
      );
      if (Object.entries(response.data).length === 0 && response.data.constructor === Object) {
        commit('clearAppointmentDose');
      } else if (response.data.sex === 'FEMALE') {
        commit('setRecommendedFemaleDose', response.data);
      } else if (response.data.sex === 'MALE') {
        commit('setRecommendedMaleDose', response.data);
      }
    },
    fetchConsultAppointmentInsertion: async ({ commit }, payload) => {
      const { locationId, patientId, appointmentId } = payload;
      const response = await axios.get(
        `/dosing/v1/location/${locationId}/patient/${patientId}/appointment/${appointmentId}`,
      );
      commit('setCurrentInsertion', response.data);
    },

    updateConsultationAppointment: async ({ commit }, payload) => {
      const { locationId, aptId } = payload;
      const body = {
        patientId: payload.patientId,
        doctorId: payload.doctorId,
        type: payload.type,
        date: payload.date,
        notes: payload.notes,
        status: payload.status,
      };
      const response = await axios.put(
        `/patient/v1/location/${locationId}/appointment/${aptId}`,
        body,
      );
      if (response.status === 200) {
        commit('setCurrentAppointment', response.data);
      }
    },

    // Calculations
    calculateMaleDose: async ({ commit, dispatch, getters }, payload) => {
      const { locationId, patientId, appointmentId } = payload;
      const body = {
        // Meta
        dosingUserId: payload.dosingUserId,
        labId: payload.labId,
        // Optional Updates
        lastVisit: payload.lastVisit,
        weight: payload.weight,
        // Patient Medical History
        activeLiverDisease: payload.activeLiverDisease,
        bph: payload.bph,
        colonCancer: payload.colonCancer,
        diabetes: payload.diabetes,
        hypertension: payload.hypertension,
        metabolicSyndrome: payload.metabolicSyndrome,
        prostateCancer: payload.prostateCancer,
        renalDisease: payload.renalDisease,
        testicularCancer: payload.testicularCancer,
        // Other
        currentTestosteroneLevel: payload.currentTestosteroneLevel,
        currentTrocheUse: payload.currentTrocheUse,
        currentTopicalUse: payload.currentTopicalUse,
        currentInjectableUse: payload.currentInjectableUse,
        exercisePerWeek: payload.exercisePerWeek,
      };
      const response = await axios.post(
        `dosing/v1/location/${locationId}/patient/${patientId}/appointment/${appointmentId}/calculate/m`,
        body,
      );
      if (response.status === 200) {
        const dispatchPayload = {
          locationId,
          aptId: getters.getCurrentAppointment.summary.id,
          patientId: getters.getCurrentAppointment.summary.patient.id,
          doctorId: getters.getCurrentAppointment.summary.doctorId,
          type: getters.getCurrentAppointment.summary.type,
          date: getters.getCurrentAppointment.summary.date,
          status: 'calculated',
        };
        dispatch('updateAppointment', dispatchPayload);
        commit('setRecommendedMaleDose', response.data);
      }
    },
    calculateFemaleDose: async ({ commit, dispatch, getters }, payload) => {
      const { locationId, patientId, appointmentId } = payload;
      const body = {
        // Meta
        dosingUserId: payload.dosingUserId,
        labId: payload.labId,
        // Optional Updates
        lastVisit: payload.lastVisit,
        weight: payload.weight,
        // Patient Medical History
        heavyMenses: payload.heavyMenses,
        renalDisease: payload.renalDisease,
        breastCancer: payload.breastCancer,
        ovarianCancer: payload.ovarianCancer,
        metabolicSyndrome: payload.metabolicSyndrome,
        pcos: payload.pcos,
        activeLiverDisease: payload.activeLiverDisease,
        cervicalCancer: payload.cervicalCancer,
        fluidRetention: payload.fluidRetention,
        bleeding: payload.bleeding,
        persistentBreastPain: payload.persistentBreastPain,
        persistentHeadache: payload.persistentHeadache,
        facialHair: payload.facialHair,
        hairLoss: payload.hairLoss,
        menstrualMigraines: payload.menstrualMigraines,
        hysterectomy: payload.hysterectomy,
        premenopausal: payload.premenopausal,
        postmenopausal: payload.postmenopausal,
        ovariesRemoved: payload.ovariesRemoved,
        // Other
        conjugatedEstrogen: payload.conjugatedEstrogen,
        currentFshLevel: payload.currentFshLevel,
        currentTestosteroneLevel: payload.currentTestosteroneLevel,
        currentEstradiolLevel: payload.currentEstradiolLevel,
        estradiolPills: payload.estradiolPills,
        estradiolPatch: payload.estradiolPatch,
        biestTriestCreamTab: payload.biestTriestCreamTab,
        estradiolCream: payload.estradiolCream,
        testosteroneCream: payload.estradiolCream,
        exercisePerWeek: payload.exercisePerWeek,
      };
      const response = await axios.post(
        `dosing/v1/location/${locationId}/patient/${patientId}/appointment/${appointmentId}/calculate/f`,
        body,
      );
      if (response.status === 200) {
        const dispatchPayload = {
          locationId,
          aptId: getters.getCurrentAppointment.summary.id,
          patientId: getters.getCurrentAppointment.summary.patient.id,
          doctorId: getters.getCurrentAppointment.summary.doctorId,
          type: getters.getCurrentAppointment.summary.type,
          date: getters.getCurrentAppointment.summary.date,
          status: 'calculated',
        };
        dispatch('updateAppointment', dispatchPayload);
        commit('setRecommendedFemaleDose', response.data);
      }
    },
    addDosingInsertion: async ({ dispatch, getters }, payload) => {
      const { locationId, calculationId } = payload;
      const body = {
        side: payload.side,
        location: payload.location,
        pellets: payload.pellets,
        notes: payload.notes,
      };
      const response = await axios.post(
        `dosing/v1/location/${locationId}/calculation/${calculationId}/insert`,
        body,
      );
      const dispatchPayload = {
        locationId,
        aptId: getters.getCurrentAppointment.summary.id,
        patientId: getters.getCurrentAppointment.summary.patient.id,
        doctorId: getters.getCurrentAppointment.summary.doctorId,
        type: getters.getCurrentAppointment.summary.type,
        date: getters.getCurrentAppointment.summary.date,
        status: 'inserted',
      };
      return dispatch('updateAppointment', dispatchPayload);
    },

    updateDosingInsertion: async ({ dispatch, getters }, payload) => {
      const { locationId, calculationId } = payload;
      const body = {
        side: payload.side,
        location: payload.location,
        notes: payload.notes,
      };
      const response = await axios.put(
        `dosing/v1/location/${locationId}/calculation/${calculationId}/insert`,
        body,
      );
      const dispatchPayload = {
        locationId,
        aptId: getters.getCurrentAppointment.summary.id,
        patientId: getters.getCurrentAppointment.summary.patient.id,
        doctorId: getters.getCurrentAppointment.summary.doctorId,
        type: getters.getCurrentAppointment.summary.type,
        date: getters.getCurrentAppointment.summary.date,
        status: 'inserted',
      };
      return dispatch('updateAppointment', dispatchPayload);
    },

    // Commits
    commitCurrentAppointment: ({ commit }, payload) => {
      commit('setCurrentAppointment', payload);
    },

    fetchPatientDetail: async ({ commit }, payload) => {
      const { locationId, patientId } = payload;
      const response = await axios.get(
        `/dosing/v1/location/${locationId}/patient/${patientId}`,
      );
      commit('setPatientDetail', response.data);
    },

  },
};
