import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

export default {
  namespaced: true,
  state: {
    currentLocationDetails: null,
    orgLocationDetails: null,
    medicalLicenses: [],
    deaLicenses: [],
    userLocations: [],
    availableLocations: [],
    currentLocation: localStorage.getItem("currentLocation") || null,
    locationAllPatients: [],
    locationMedcenterPatients: [],
    locationIntellipelPatients: [],
    currentUserAvailableLocation: {
      data: [],
      dataFilter: {}
    }
  },
  getters,
  mutations,
  actions,
};
